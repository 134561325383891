import { computed, inject } from 'vue';
import eventBus from '../../common/js/event-bus/event-bus';

const CONTAINER_SELECTORS = {
	HEADER_SELECTOR: '.mevp-card-header',
	TEXT_SELECTOR: '.mevp-card-text'
};

const calculateItemHeights = () => {
	const headers = document.querySelectorAll(CONTAINER_SELECTORS.HEADER_SELECTOR);
	const texts = document.querySelectorAll(CONTAINER_SELECTORS.TEXT_SELECTOR);
	return [headers, texts];
};

const checkHeights = () => {
	const elements = calculateItemHeights();
	for (let i = 0; i < elements.length; i++) {
		for (let j = 0; j < elements[i].length; j += 2) {
			if (elements[i][j + 1]) {
				const firstElement = Number.parseInt(getComputedStyle(elements[i][j]).height, 10);
				const secondElement = Number.parseInt(getComputedStyle(elements[i][j + 1]).height, 10);
				if (firstElement > secondElement) {
					elements[i][j + 1].style.height = getComputedStyle(elements[i][j]).height;
				} else {
					elements[i][j].style.height = getComputedStyle(elements[i][j + 1]).height;
				}
			}
		}
	}
	for (let elementList of elements) {
		for (let j = 0; j < elementList.length; j += 2) {
			if (elementList[j + 1]) {
				const firstElement = Number.parseInt(getComputedStyle(elementList[j]).height, 10);
				const secondElement = Number.parseInt(getComputedStyle(elementList[j + 1]).height, 10);
				if (firstElement > secondElement) {
					elementList[j + 1].style.height = getComputedStyle(elementList[j]).height;
				} else {
					elementList[j].style.height = getComputedStyle(elementList[j + 1]).height;
				}
			}
		}
	}
};

export default {
	props: {
		isNewCustomer: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			detailsOpened: false
		};
	},
	setup() {
		const userHasDszk = inject('userHasDszk');
		const userHasDirekt = inject('userHasDirekt');
		const noDirektOrDszk = computed(() => !userHasDszk.value && !userHasDirekt.value);
		return {
			userHasDirekt,
			userHasDszk,
			noDirektOrDszk
		};
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		},
		firstInDetails() {
			return this.$el.querySelector('#mevp_0_details').firstChild;
		}
	},
	watch: {
		detailsOpened(newVal) {
			if (!this.isMobile) {
				this.$nextTick(() => {
					checkHeights();
					if (newVal) {
						this.firstInDetails.focus();
					} else {
						this.$refs.details.focus();
					}
				});
			}
		}
	},
	mounted() {
		if (!this.isMobile) {
			checkHeights();
		}
		eventBus.$on('modal-opened', () => {
			this.$nextTick(() => {
				checkHeights();
			});
		});
	},
	beforeDestroy() {
		eventBus.$off('modal-opened');
	}
};
